
import AnalyticsService from '@js/modules/analyticsService';


const PWAManager = (function () {
    function PWAManagerModel() {
        // this._model = {
        //     // Install prompt
        //     deferredInstallPrompt: undefined
        // };

        /* Private Methods
         ******************** */
        this._isSubscribedUser = function () {
            // Check if user can install PWA
            return window.currentUserId;
        };

        // this._watchInstallPrompt = function (event) {
        //     this._model.deferredInstallPrompt = event;
        //
        //     return event;
        // }

        this._logAppInstalled = function (event) {
            // App was installed

            AnalyticsService.trackPWAInstalled();

            return event;
        };
    }

    const pwaManager = PWAManagerModel.prototype;

    pwaManager.MODE = {
        PWA: 'pwa',
        BROWSER: 'browser'
    };

    /* Public Methods
     ******************** */
    pwaManager.initialize = function () {
        if (process.env.NODE_ENV !== 'production' || window.seoMode) {
            return;
        }

        if (!this._isSubscribedUser()) {
            return;
        }

        if ('serviceWorker' in navigator) {
            window.addEventListener('load', () => {
                // SW is generated by Workbox plugin
                navigator.serviceWorker.register('/service-worker.js')
                    .then(() => {
                        // Service worker registered: registration
                        AnalyticsService.trackPWAMode(this.getPWADisplayMode());
                    })
                    .catch(() => {
                        // Service worker registration failed: registrationError
                    });
            });
        }

        // window.addEventListener('beforeinstallprompt', this._watchInstallPrompt.bind(this));

        window.addEventListener('appinstalled', this._logAppInstalled.bind(this));
    };

    // pwaManager.installPWAPrompt = function () {
    //     if (!this._model.deferredInstallPrompt) {
    //         return;
    //     }
    //
    //     if (typeof this._model.deferredInstallPrompt.prompt !== 'function') {
    //         return;
    //     }
    //
    //     this._model.deferredInstallPrompt.prompt();
    //
    //     this._model.deferredInstallPrompt.userChoice
    //         .then((choice) => {
    //             if (choice.outcome === 'accepted') {
    //                 console.info('User accepted the A2HS prompt', choice);
    //             } else {
    //                 console.info('User dismissed the A2HS prompt', choice);
    //             }
    //             this._model.deferredInstallPrompt = null;
    //         });
    //
    //     AnalyticsService.trackPWAPrompt();
    // };

    pwaManager.getPWADisplayMode = function () {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

        if (document.referrer.startsWith('android-app://')) {
            // twa
            return pwaManager.MODE.PWA;
        } else if (navigator.standalone || isStandalone) {
            // pwa
            return pwaManager.MODE.PWA;
        }

        return pwaManager.MODE.BROWSER;
    };

    pwaManager.clearServiceWorkerCaches = function () {
        if ('serviceWorker' in navigator) {
            caches.keys()
                .then(function (cacheNames) {
                    cacheNames.forEach(function (cacheName) {
                        caches.delete(cacheName);
                    });
                });
        }
    };

    return PWAManagerModel;
})();

export default new PWAManager();
